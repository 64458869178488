/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-12 13:37:30
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';
export var models = genModels(function (genRules, ctx) {return [
  /*{
                                                                  span: 12,
                                                                  kname: 'title',
                                                                  itemProps: {
                                                                    label: '标题：',
                                                                    rules: [genRules('require')]
                                                                  },
                                                                  component: {
                                                                    name: 'el-input',
                                                                    props: {
                                                                      maxlength: 30
                                                                    }
                                                                  }
                                                                },
                                                                {
                                                                  span: 12,
                                                                  kname: 'typeId',
                                                                  itemProps: {
                                                                    label: '分类：',
                                                                    rules: [genRules('require')]
                                                                  },
                                                                  component: {
                                                                    name: 'selector',
                                                                    props: {
                                                                      type: 'articleType',
                                                                      disableStatus: 1
                                                                    }
                                                                  }
                                                                },
                                                                {
                                                                  kname: 'desc',
                                                                  itemProps: {
                                                                    label: '描述：',
                                                                    rules: [genRules('require')]
                                                                  },
                                                                  component: {
                                                                    name: 'el-input',
                                                                    props: {
                                                                      maxlength: 100
                                                                    }
                                                                  }
                                                                },*/
  {
    kname: 'coverAddress',
    itemProps: {
      label: '封面图片：'
      //rules: [genRules('require')]
    },
    component: {
      name: 'upload-image',
      props: {
        limit: 3 } } }



  /*{
                         kname: 'details',
                         itemProps: {
                           label: '详情：'
                         },
                         component: {}
                       },
                       {
                         kname: 'fileAddress',
                         itemProps: {
                           label: '附件文件：'
                         },
                         component: {
                           name: 'upload-file',
                           props: {
                             limit: 1,
                             accept: '.pdf'
                           }
                         }
                       }*/];});



// form 默认值
export var formData = function formData() {return {};};